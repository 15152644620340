import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.toastr, action) => {
  switch (action.type) {
    case 'ADD_TOASTR':
      return action.payload;
    default:
      return state;
  }
};

import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.feedback, action) => {
  switch (action.type) {
    case types.SEND_FEEDBACK:
      return action.payload;
    case types.SEND_FEEDBACK_ERROR:
      return action.payload;
    default:
      return state;
  }
};

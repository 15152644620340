import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.needs, action) => {
  switch (action.type) {
    case types.INDEX_NEEDS:
      return action.payload;
    case types.EXCLUDE_FORYOU_NEEDS_API:
      return action.payload;
    case types.INDEX_NEEDS_WITHFILTER:
      return action.payload;
    default:
      return state;
  }
};

import axios from 'axios';

import Auth from './auth';
import { toast } from './toast';

export const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_HOST_ENV === 'local' ? process.env.REACT_APP_SERVER_URL : ''}/api`,
  headers: { Authorization: `Bearer ${Auth.getJwt()}` },
});

apiClient.interceptors.response.use(function(response) {
  if (response.data.lumisAction) {
    toast(response.data.lumisAction);
  }

  return response;
});

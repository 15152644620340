import { css } from 'styled-components';
import variables from '../variables';

const { breakpoints } = variables.grid;

const sizes = {
  xs: breakpoints.xs,
  sm: breakpoints.sm,
  md: breakpoints.md,
  mdIpad: breakpoints.md+1,
  lg: breakpoints.lg,
  xlg: breakpoints.xlg
};

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export const mediaMax = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

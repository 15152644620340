import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.coaches, action) => {
  switch (action.type) {
    case types.INDEX_COACHES:
      return {
        data: action.payload.data,
      };
    default:
      return state;
  }
};

import redirect from './redirect';
import { setCookie, getCookie, removeCookies } from './cookies';
import { pageRoutes } from '../const/routes';

/**
 /* Authorization utilities and redirects
*/
export default class Auth {
  static login = ({ token, role, ...user }) => {
    setCookie('user', JSON.stringify(user));
    setCookie('jwt', token);
    if (role === 'admin') {
      setCookie('role', 'admin');
    }
    setCookie('last_login', new Date().getTime());
    Auth.changeLanguage(user.lang);
    return null;
  };

  static logout = (path = pageRoutes.LANDING_PATH, ctx = {}) => {
    Auth.clear();
    window.location.href = path;
  };

  static clear = (ctx = {}) => {
    removeCookies(['user', 'jwt', 'role', 'lang', 'white_label'], ctx.res);
  };

  static changeLanguage = (lang, ctx = {}) => {
    switch(lang){
      case 'EN':
      case 'FR':
      case 'PT':
        setCookie('lang', lang, 30, ctx);
        break;
      default:
        setCookie('lang', 'EN', 30, ctx);
    }
  };

  static getLanguage = (ctx = {}) => {
    const lang = getCookie('lang', ctx.req);

    if (!lang) {
      return Auth.getUser()?.lang || 'EN';
    }

    return lang;
  };

  static getUser = (ctx = {}) => {
    let user = null;
    if (getCookie('user', ctx.req)) {
      user = JSON.parse(
        getCookie('user', ctx.req)
          .replace(/%22/g, '"')
          .replace(/%2C/g, ',')
      );
    }

    return {
      isAuthenticated: !!Auth.getJwt(ctx),
      jwt: getCookie('jwt', ctx.req),
      isAdmin: getCookie('role', ctx.req) === 'admin',
      ...(user && user),
    };
  };

  static getJwt = (ctx = {}) => getCookie('jwt', ctx.req);

  static isAuthenticated = ctx => !!Auth.getJwt(ctx);

  static redirectIfAuthenticated = (ctx, path = pageRoutes.HOME_PATH) => {
    if (Auth.isAuthenticated(ctx)) {
      redirect(path, ctx);
      return true;
    }
    return false;
  };

  static redirectIfNotAdmin = ctx => {
    const user = Auth.getUser(ctx);
    if (!user.isAdmin) {
      redirect(pageRoutes.LOGIN_ADMIN_PATH, ctx);
    }
    return false;
  };

  static redirectIfNotAuthenticated = (ctx, path = pageRoutes.LANDING_PATH) => {
    if(ctx.req && ctx.req.path == pageRoutes.SESSIONS_LIBRARY_PATH && ctx.req.query.session)
      setCookie('session',ctx.req.query.session,30,ctx);
    if(ctx.req && ctx.req.path == '/home' && ctx.req.query.program)
      setCookie('program',ctx.req.query.program,30,ctx);
    if (!Auth.isAuthenticated(ctx)) {
      Auth.clear(ctx);
      redirect(path, ctx);
      return true;
    }
  };

  static isFirstLogin = (ctx = {}) => {
    if (!getCookie('home_views', ctx.req)) {
      setCookie('home_views', 1);
      return true;
    }
    return false;
  };
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: null,
  status: 'idle',
  error: null,
};

export const fetchSettings = createAsyncThunk('userSettingsSlice/fetchSettings', async () => {
  const response = await apiClient.get('/v1/user/settings');
  return response.data;
});

export const updateSettings = createAsyncThunk('userSettingsSlice/updateSettings', async settings => {
  const response = await apiClient.post('/v1/user/settings', settings);
  return response.data;
});

const userSettingsSlice = createSlice({
  name: 'userSettingsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSettings.pending]: state => {
      state.status = 'loading';
    },
    [fetchSettings.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchSettings.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [updateSettings.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
  },
});

export default userSettingsSlice.reducer;

import types from './actions/types';

const initialState = {
  data: [],
  errors: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_PROFILE_SKILLS:
      return action.payload;
    default:
      return state;
  }
};

import fetch from 'isomorphic-fetch';
import request from './request';
import errors from '../const/errors';
import { toast } from './toast';
import Auth from './auth';
import { pageRoutes } from '../const/routes';

export default (dispatch, data) => {
  return fetch(
    `${process.env.REACT_APP_HOST_ENV === 'local' ? process.env.REACT_APP_SERVER_URL : ''}${data.route}`,
    request({
      method: data.method,
      authorization: true,
      ...(data.headers && { headers: data.headers }),
      ...(data.ctx && { ctx: data.ctx }),
      ...(data.body && { body: data.body }),
    }),
  )
    .then(res => {
      if (res.status === 500) {
        throw new Error(errors.internalServerError);
      }
      if (
        res.status === 401 &&
        (window.location.pathname !== pageRoutes.LOGIN_USER_PATH && window.location.pathname !== pageRoutes.PRIVACY_PATH)
      ) {
        Auth.logout(pageRoutes.LOGIN_USER_PATH);
      }
      return res.json();
    })
    .then(res => {
      if (res.errors && data.action_error) {
        return dispatch({
          type: data.action_error,
          payload: res,
          ...(data.options && { options: data.options }),
        });
      }
      if (res.lumisAction) {
        toast(res.lumisAction);
      }
      return dispatch({
        type: data.action,
        payload: res,
        ...(data.options && { options: data.options }),
      });
    })
    .catch(err => {
      if (data.action_error) {
        return dispatch({
          type: data.action_error,
          payload: {
            errors: [errors.internalServerError],
          },
        });
      }
    });
};

import types from './actions/types';

const initialState = {
  data: null,
  errors: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MENTORS:
      return {
        data: action.payload.mentors,
        errors: null,
      };
    case types.ADD_MENTOR:
    case types.REMOVE_MENTOR:
      return {
        data: state.data,
        errors: action.payload.errors || (action.payload.errorId ? [action.payload.errorId] : null),
      };
    default:
      return state;
  }
};

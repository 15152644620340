import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../../services/apiClient';

const initialState = {
  programs: {
    data: [],
    status: 'idle',
  },
  status: {
    data: [],
    status: 'idle',
  },
  steps: {
    data: [],
    status: 'idle',
  },
  filters: {
    data: [],
    status: 'idle',
  },
  fileStatus: 'idle',
  error: null,
};

export const fetchPrograms = createAsyncThunk('analyticProgram/fetchPrograms', async ({ company, tagId, partner }) => {
  const body = {
    company,
    tagId,
    partner,
  }
  const response = await apiClient.post('/v1/analytic/program', body);
  return response.data;
});

export const fetchStatus = createAsyncThunk('analyticProgram/fetchStatus', async ({ fromDate, toDate, company, programId, tagId, partner }) => {
  const body = {
    fromDate,
    toDate,
    includeDeleted: true,
    includeTeamTag: false,
    company,
    programId,
    tagId,
    partner,
  }
  const response = await apiClient.post('/v1/analytic/program/status', body);
  return response.data;
});

export const fetchSteps = createAsyncThunk('analyticProgram/fetchSteps', async ({ fromDate, toDate, company, programId, tagId, partner }) => {
  const body = {
    fromDate,
    toDate,
    includeDeleted: true,
    includeTeamTag: false,
    company,
    programId,
    tagId,
    partner,
  }
  const response = await apiClient.post('/v1/analytic/program/steps', body);
  return response.data;
});

export const fetchFilters = createAsyncThunk('analyticProgram/fetchFilters', async () => {
  const response = await apiClient.get('/v2/analytic/filters');
  return response.data;
});

export const downloadFile = createAsyncThunk('analyticProgram/downloadFile', async({ fromDate, toDate, company, programId, tagId, partner }) => {
  const body = {
    fromDate,
    toDate,
    includeDeleted: true,
    includeTeamTag: false,
    company,
    programId,
    tagId,
    partner,
  }
  const response = await apiClient.post('/v1/analytic/program/download-xlsx', body, {responseType: 'blob'} );
  return response.data;
});
export const downloadNewFile = createAsyncThunk('analyticProgram/downloadNewFile', async({ fromDate, toDate, company, programId, tagId, partner })  => {
  const body = {
    fromDate,
    toDate,
    includeDeleted: true,
    includeTeamTag: false,
    company,
    programId,
    tagId,
    partner,
  }
  const response = await apiClient.post('/v2/analytic/user-program/download', body, {responseType: 'blob'} );
  return response.data;
});

const analyticProgram = createSlice({
  name: 'analyticProgram',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPrograms.pending]: state => {
      state.programs.status = 'loading';
    },
    [fetchPrograms.fulfilled]: (state, action) => {
      state.programs.status = 'succeeded';
      state.programs.data = action.payload;
    },
    [fetchPrograms.rejected]: (state, action) => {
      state.programs.status = 'failed';
      state.error = action.payload;
    },
    [fetchStatus.pending]: state => {
      state.status.status = 'loading';
    },
    [fetchStatus.fulfilled]: (state, action) => {
      state.status.status = 'succeeded';
      state.status.data = action.payload;
    },
    [fetchStatus.rejected]: (state, action) => {
      state.status.status = 'failed';
      state.error = action.payload;
    },
    [fetchSteps.pending]: state => {
      state.steps.status = 'loading';
    },
    [fetchSteps.fulfilled]: (state, action) => {
      state.steps.status = 'succeeded';
      state.steps.data = action.payload;
    },
    [fetchSteps.rejected]: (state, action) => {
      state.steps.status = 'failed';
      state.error = action.payload;
    },
    [fetchFilters.pending]: state => {
      state.filters.status = 'loading';
    },
    [fetchFilters.fulfilled]: (state, action) => {
      state.filters.status = 'succeeded';
      state.filters.data = action.payload;
    },
    [fetchFilters.rejected]: (state, action) => {
      state.filters.status = 'failed';
      state.error = action.payload;
    },

    [downloadFile.pending]: state => {
      state.fileStatus = 'loading';
    },
    [downloadFile.fulfilled]: (state, action) => {
      state.fileStatus = 'succeeded';
    },
    [downloadFile.rejected]: (state, action) => {
      state.fileStatus = 'failed';
      state.error = action.payload;
    },

    [downloadNewFile.pending]: state => {
      state.fileStatus = 'loading';
    },
    [downloadNewFile.fulfilled]: (state, action) => {
      state.fileStatus = 'succeeded';
    },
    [downloadNewFile.rejected]: (state, action) => {
      state.fileStatus = 'failed';
      state.error = action.payload;
    },
  },
});

export default analyticProgram.reducer;

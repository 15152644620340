import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: {},
  status: 'idle',
  error: null,
};

export const getInsightById = createAsyncThunk('insight/getInsightById', async (id) => {
  const response = await apiClient.get(`/v2/insights/${id}`);
  return response.data;
});

const insightSlice = createSlice({
  name: 'insightByIdSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getInsightById.pending]: state => {
      state.status = 'loading';
    },
    [getInsightById.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [getInsightById.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default insightSlice.reducer;

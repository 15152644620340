import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

export const colorsArr = [
  {
    text: '#fff',
    background: '#357588',
  },
  {
    text: '#fff',
    background: '#FE9486',
  },
  {
    text: '#fff',
    background: '#675F72',
  },
  {
    text: '#fff',
    background: '#BFB0A3',
  },
  {
    text: '#fff',
    background: '#A29BA4',
  },
  {
    text: '#fff',
    background: '#E9B665',
  },
  {
    text: '#fff',
    background: '#FF7864',
  },
  {
    text: '#211E42',
    background: '#F6B09E',
  },
  {
    text: '#211E42',
    background: '#F8DE8B',
  },
  {
    text: '#211E42',
    background: '#DAC1A8',
  },
  {
    text: '#211E42',
    background: '#BFD0CA',
  },
  {
    text: '#211E42',
    background: '#FAF3DA',
  },
  {
    text: '#211E42',
    background: '#B6CCD3',
  },
  {
    text: '#211E42',
    background: '#F4DBD1',
  },
  // {
  //   text: '#fff',
  //   background: '#fff',
  // },
  // {
  //   text: '#000',
  //   background: '#000',
  // },
  // {
  //   text: 'red',
  //   background: 'red',
  // },
];

export const currentColor = usedColors => {
  if (usedColors?.length === 0 || !usedColors) {
    return colorsArr[Math.floor(Math.random() * colorsArr.length)];
  }
  const usedBgColors = usedColors.map(item => item.color.background);
  const allBgColors = colorsArr.map(item => item.background);
  const usedColorsCount = allBgColors.concat(usedBgColors).reduce((item, index) => ((item[index] = (item[index] || 0) + 1), item), {});
  const minValue = Math.min(...Object.values(usedColorsCount).map(item => item));
  const minFilteredColors = Object.keys(usedColorsCount).filter(key => usedColorsCount[key] === minValue);
  const newColors = colorsArr.filter(item => minFilteredColors.includes(item.background));

  return newColors[Math.floor(Math.random() * newColors.length)];
};

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchInsights = createAsyncThunk('insights/fetchInsights', async () => {
  const response = await apiClient.get(`/v2/insights`);
  return response.data;
});

export const addInsight = createAsyncThunk('insights/addInsights', async ({ content, insightTitle, color, belongsTo, belongsToId }) => {
  const insight = {
    content,
    pageTitle: document.title.replace('Luminous - ', ''),
    pageLocation: window.location.pathname + (window.location.hash || window.location.search),
    color,
    insightTitle,
    belongsTo,
    belongsToId,
  };

  const response = await apiClient.post(`/v2/insights`, insight);
  return response.data;
});

export const updateInsight = createAsyncThunk('insights/updateInsights', async ({ insightId, content, insightTitle }) => {
  const insight = {
    content,
    insightTitle,
  };
  const response = await apiClient.put(`/v2/insights/${insightId}`, insight);
  return response.data;
});

export const deleteInsight = createAsyncThunk('insight/deleteInsight', async id => {
  await apiClient.delete(`/v2/insights/${id}`);
  return id;
});

export const journalingInsight = createAsyncThunk('insight/journalingInsight', async ({ userId, insightId, sourceType, sourceName }) => {
  const body = {
    userId,
    insightId,
    sourceType,
    sourceName,
  };
  return await apiClient.post(`/v2/insights/journaling`, body);
});

export const getUserProgramInsights = createAsyncThunk('insight/getUserProgramInsights', async userProgramId => {
  const response = await apiClient.get(`/v2/user-program/insights/${userProgramId}`);

  return response.data;
});

const insightsSlice = createSlice({
  name: 'insightsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchInsights.pending]: state => {
      state.status = 'loading';
    },
    [fetchInsights.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      // state.data = action.payload.length > 0 ? action.payload : initialState.data;
      state.data = action.payload;
    },
    [fetchInsights.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [addInsight.fulfilled]: (state, action) => {
      state.data = [action.payload, ...state.data];
    },
    [addInsight.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [updateInsight.fulfilled]: (state, action) => {
      const stateData = state.data.filter(item => item.id !== action.payload.id);
      state.data = [action.payload, ...stateData];
    },
    [updateInsight.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [deleteInsight.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = state.data.filter(item => item.id !== action.payload);
    },
    [deleteInsight.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [getUserProgramInsights.pending]: state => {
      state.status = 'loading';
    },
    [getUserProgramInsights.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [getUserProgramInsights.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default insightsSlice.reducer;

import types from './actions/types';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case types.PROGRAM_SEARCH:
      return action.payload;
    case types.PROGRAM_SEARCH_ERROR:
      return [];
    default:
      return state;
  }
};

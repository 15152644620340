import styled, { css } from 'styled-components';
import { ReactComponent as Sparkles } from '../../images/emoji_sparkles.svg';

export const RewardRelative = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
`;

export const RewardLeft = styled.div`
  position: absolute;
  height: 28px;
  left: 24px;
  top: calc(50% - 28px/2 - 5px);
  text-align: center;
`;

export const RewardRight = styled.div`
  position: absolute;
  width: auto;
  max-width: calc(100% - 8px - 70px - 24px);
  height: 48px;
  right: 8px;
  top: calc(50% - 48px/2);
  text-align: center;

  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.03em;
  color: #FFFFFF;
  text-shadow: 0px 3.38082px 10.0172px rgba(0, 0, 0, 0.29), 0px 27px 80px rgba(0, 0, 0, 0.145);
`;

export const RewardEmoji = styled(Sparkles)`
  margin-left: 8px;
  width: 69px;
  height: 48px;
  display: flex;
`


export const RewardLumisNumber = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 100%;
  letter-spacing: -0.05em;
  color: #FFFFFF;
`;


export const RewardLumisText = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: -0.05em;
  color: #FFFFFF;
`;

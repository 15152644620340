import fetch from 'isomorphic-fetch';
import request from './request';

export default class Heartbeat {
  static send() {
    fetch(
      `${process.env.REACT_APP_HOST_ENV === 'local' ? process.env.REACT_APP_SERVER_URL : ''}${
        process.env.REACT_APP_API_URL
      }/heartbeat?status=active`,
      request({
        method: 'POST',
        authorization: true,
      }),
    );
  }
}

import Auth from './auth';
import EN from '../locales/en.json';
import FR from '../locales/fr.json';
import PT from '../locales/pt.json';

const langs = {
  EN,
  FR,
  PT,
};

export default {
  load: () => {
    let lang = null;

    lang = Auth.getLanguage();

    if (langs[lang]) {
      return langs[lang];
    }
    return {};
  },
};

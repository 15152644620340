import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.admin, action) => {
  switch (action.type) {
    case types.LOGIN_ADMIN:
      return action.payload;
    case types.LOGIN_ADMIN_ERROR:
      return {
        ...state,
        ...{
          errors: action.payload.errors[0],
        },
      };
    default:
      return state;
  }
};

import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.userActions, action) => {
  switch (action.type) {
    case types.INDEX_FROM_USER_ACTIONS:
    case types.UPDATE_FROM_USER_ACTIONS:
    case types.UPDATE_ALL_FROM_USER_ACTIONS:
      return {
        data: action.payload.data,
      };
    case types.DELETE_FROM_USER_ACTIONS: {
      return {
        data: state.data.filter(
          userAction => userAction.id !== action.options.id
        ),
      };
    }
    default:
      return state;
  }
};

import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.goal, action) => {
  switch (action.type) {
    case types.SHOW_GOAL:
    case types.SHOW_GOAL_NOT_FOUND:
      return action.payload;
    case types.CREATE_GOAL:
      return action.payload;
    case types.CREATE_GOAL_ERROR:
      return {
        errors: action.payload.errors[0],
      };
    case types.UPDATE_GOAL:
      return action.payload;
    case types.UPDATE_GOAL_ERROR:
      return {
        errors: action.payload.errors[0],
      };
    default:
      return state;
  }
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../../services/apiClient';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchProgramEvents = createAsyncThunk('userProgram/fetchProgramEvents', async userProgramId => {
  const { data } = await apiClient.get(`/v2/user-program/events/${userProgramId}`);

  return data;
});

export const fetchUpdateEvent = createAsyncThunk('userProgram/fetchUpdateEvent', async ({userProgramId, eventId, title, date}) => {
  const body = {
    title,
    date
  };
  const { data } = await apiClient.put(`/v2/user-program/${userProgramId}/event/${eventId}`, body);

  return data;
});

const programEventsSlice = createSlice({
  name: 'programEventsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProgramEvents.pending]: state => {
      state.status = 'loading';
    },
    [fetchProgramEvents.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchProgramEvents.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [fetchUpdateEvent.pending]: state => {
      state.status = 'loading';
    },
    [fetchUpdateEvent.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [fetchUpdateEvent.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default programEventsSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchAllSessions = createAsyncThunk('session/fetchAllSessions', async () => {
  const response = await apiClient.get('/v2/sessions/completed');
  return response.data;
});

const sessionsAllSlice = createSlice({
  name: 'sessionsAllSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllSessions.pending]: state => {
      state.status = 'loading';
    },
    [fetchAllSessions.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      // console.log(state.data)
    },
    [fetchAllSessions.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default sessionsAllSlice.reducer;

import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.article, action) => {
  switch (action.type) {
    case types.SHOW_ARTICLE:
    case types.SHOW_ARTICLE_NOT_FOUND:
      return action.payload.data;
    default:
      return state;
  }
};

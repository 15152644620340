import types from './types';
import authenticatedRequest from '../../services/api';
import { apiRoutes } from '../../const/routes';

const UserSettings = {
  getUserSettings() {
    return dispatch =>
      authenticatedRequest(dispatch, {
        route: apiRoutes.GET_USER_SETTINGS_API,
        action: types.GET_USER_SETTINGS,
        method: 'GET'
      });
  },
  updateUserSettings(body) {
    return dispatch =>
      authenticatedRequest(dispatch, {
        route: apiRoutes.GET_USER_SETTINGS_API,
        action: types.UPDATE_USER_SETTINGS,
        method: 'POST',
        body
      });
  },
};

export default UserSettings;

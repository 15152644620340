/* eslint-disable camelcase */
import Auth from './auth';

/**
 /* Apply parameters for each request:
 /* method, content-type, authorization type, and body
*/
export default ({
  method,
  authorization,
  headers = {
    'Content-Type': 'application/json',
  },
  body,
  ctx,
}) => {
  const request = {
    method,
    headers: !headers['Content-Type'].includes('multipart') ? headers : {},
  };

  if (authorization) {
    Object.assign(request.headers, {
      Authorization: `Bearer ${Auth.getJwt(ctx)}`,
    });
  }
  if (body) {
    Object.assign(request, {
      body: headers['Content-Type'].includes('json')
        ? JSON.stringify(body)
        : body,
    });
  }
  return request;
};

import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.notes, action) => {
  switch (action.type) {
    case types.INDEX_NOTES:
    case types.CREATE_NOTE:
      return {
        data: action.payload.data
      };
    default:
      return state;
  }
};

export const setWithExpiry = (value, ttl) => {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem('chunkFailed', JSON.stringify(item));
};

export const getWithExpiry = () => {
  const itemString = localStorage.getItem('chunkFailed');
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem('chunkFailed');
    return null;
  }

  return item.value;
};

import React from 'react';
import { connect } from 'react-redux';
import {
  ToastContainer
} from './styles';


class Toast extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toastContainer = React.createRef();
    this.state = {
      visible: props.visible || false,
      disappear: false
    };
  }

  componentDidMount() {
    if (!this.state.visible)
      this.setState({visible: true});
    setTimeout(() => {
      this.setState({disappear: true})
    }, this.props.duration || 4000);
  }

  onAnimationEnd = () => {
    if (this.state.disappear) {
      this.setState({disappear: false, visible: false});
      if (this.props.onToastEnd) this.props.onToastEnd();
      this.props.setToast();
    }
  }

  render(){
    return (
      <ToastContainer className={this.state.disappear ? 'disappear' : (this.state.visible ? "appear" : null)} ref={this.toastContainer} onAnimationEnd={() => this.onAnimationEnd()}>
        {this.props.children || this.props.content}
      </ToastContainer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setToast: () => dispatch({
    type: 'ADD_TOASTR',
    payload: null
  }),
});

export default connect(
  null,
  mapDispatchToProps
)(Toast);

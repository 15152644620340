import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.articleOpinions, action) => {
  switch (action.type) {
    case types.ARTICLE_OPINIONS_API:
      return {
        ...state,
        data: action.payload.data
      };
    default:
      return state;
  }
};

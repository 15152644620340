import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../../services/apiClient';

const initialState = {
  sessions: {
    data: [],
    status: 'idle',
  },
  activity: {
    data: [],
    status: 'idle',
  },
};

export const fetchSessionsAnalytic = createAsyncThunk('analyticSessions/fetchsessions', async () => {
  const response = await apiClient.get('/v1/session_opinions');
  return response.data;
});

export const getAnalyticSessions = createAsyncThunk('analyticSessions/getSession', async ({companies, tags, partners, programs }) => {
  const body = {
    includeDeleted: true,
    includeTeamTag: true,
    companies,
    tags,
    partners,
    programs,
  }
  const response = await apiClient.post('/v1/analytic/session', body);
  return response.data;
});

export const activitySessions = createAsyncThunk('analyticSessions/activity', async ({companies, tags, partners, programs }) => {
  const body = {
    includeDeleted: true,
    includeTeamTag: true,
    companies,
    tags,
    partners,
    programs,
  }
  const response = await apiClient.post('/v1/analytic/session/activity', body);
  return response.data;
});

export const downloadSessionsFile = createAsyncThunk('analyticSessions/download', async ({companies, tags, programs }) => {
  const body = {
    includeDeleted: true,
    includeTeamTag: true,
    companies,
    tags,
    programs,
  }
  const response = await apiClient.post('/v1/analytic/session/export', body);
  return response.data;
});

const analyticSession = createSlice({
  name: 'analyticSessions',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSessionsAnalytic.pending]: state => {
      state.status = 'loading';
    },
    [fetchSessionsAnalytic.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.payload = action.payload;
    },
    [fetchSessionsAnalytic.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [getAnalyticSessions.pending]: state => {
      state.status = 'loading';
    },
    [getAnalyticSessions.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.payload = action.payload;
    },
    [getAnalyticSessions.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [activitySessions.pending]: state => {
      state.activity.status = 'loading';
    },
    [activitySessions.fulfilled]: (state, action) => {
      state.activity.status = 'succeeded';
      state.activity.data = action.payload;
    },
    [activitySessions.rejected]: (state, action) => {
      state.activity.status = 'failed';
      state.error = action.payload;
    },

    [downloadSessionsFile.pending]: state => {
      state.fileStatus = 'loading';
    },
    [downloadSessionsFile.fulfilled]: (state, action) => {
      state.fileStatus = 'succeeded';
    },
    [downloadSessionsFile.rejected]: (state, action) => {
      state.fileStatus = 'failed';
      state.error = action.payload;
    },
  },
});

export default analyticSession.reducer;

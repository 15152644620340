import React        from 'react';
import AppContext   from '../../services/context';
import Toast        from './index';
import { connect } from 'react-redux';
import {
  RewardRelative,
  RewardLeft,
  RewardRight,
  RewardLumisNumber,
  RewardLumisText,
  RewardEmoji
} from './rewardStyles';
import {EE} from '../../services/toast';

class RewardToast extends React.PureComponent {

  constructor(props){
    super(props);
    this.actionsMap = {
      'goal.creation': 'goal_created',
      'goal.modification': 'goal_modified',
      'action.creation': 'action_created',
      'action.modification': null,
      'action.completion': 'action_completed',
      'action.completion.true': 'action_top_completed',
      'action.completion.null': 'action_completed',
      'action.completion.false': 'action_completed',
      'action.addDate': 'action_date',
      'session.completion': 'session_completed',
      'insight.creation': 'note_created',
      'profile.modification': 'profile_modified',
      'program.modification': 'program_modification',
      'program.modification.undone': 'program.modification.undone',
      'program.creation': 'program_creation'
    }
    this.state = {
      trigger: false
    }
  }

  componentDidMount() {
    const {add} = this.props;
    EE.on('add/toastr', (content) => {
      this.props.add(content);
    });
  }

  componentWillUnmount() {
    EE.removeListener('add/toastr');
  }


  renderContent = () => {
    return (
      <RewardRelative>
        <RewardLeft>
          <RewardLumisNumber>+ {this.props.toastr.nbLumis}</RewardLumisNumber>
          <RewardLumisText>lumis</RewardLumisText>
        </RewardLeft>
        <RewardRight>
          <AppContext.Consumer>
            {context => {
              return context[`lumis_toast_${this.actionsMap[this.props.toastr.action]}`]}}
          </AppContext.Consumer>
          <RewardEmoji/>
        </RewardRight>
      </RewardRelative>
    )
  }

  render(){
    if (this.props.toastr && this.props.toastr.nbLumis && (this.props.toastr.nbLumis > 0)){
        return (<Toast content={this.renderContent()}/>)
    }
    return '';
  }

}

const mapStateToProps = ({ toastr }) => ({
  toastr,
});

const mapDispatchToProps = dispatch => ({
  add: (content) => {return dispatch({
    type: 'ADD_TOASTR',
    payload: content
  })}
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardToast);

import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.program, action) => {
  switch (action.type) {
    case types.SHOW_PROGRAM:
    case types.SHOW_PROGRAM_NOT_FOUND:
    case types.SHOW_USER_PROGRAM:
      return action.payload;
    case types.CREATE_PROGRAM:
      return action.payload;
    case types.CREATE_PROGRAM_ERROR:
      return {
        errors: action.payload.errors[0],
      };
    case types.UPDATE_PROGRAM_ERROR:
      return {
        errors: action.payload.errors[0],
      };
    default:
      return state;
  }
};

import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.goalActions, action) => {
  switch (action.type) {
    case types.INDEX_FROM_GOAL_ACTIONS:
    case types.UPDATE_FROM_GOAL_ACTIONS:
    case types.UPDATE_ALL_FROM_GOAL_ACTIONS:
      return {
        data: action.payload.data
      };
    case types.DELETE_FROM_GOAL_ACTIONS: {
      return {
        data: state.data.filter(
          goalAction => goalAction.id !== action.options.id
        ),
      };
    }
    default:
      return state;
  }
};

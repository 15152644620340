import types from './actions/types';

const initialState = {
  data: [],
  errors: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PROGRAM_ACTIONS:
      return action.payload;
    default:
      return state;
  }
};

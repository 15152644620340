import types from './types';
import authenticatedRequest from '../../services/api';
import { apiRoutes } from '../../const/routes';

const Profile = {
  update(body) {
    return dispatch =>
      authenticatedRequest(dispatch, {
        route: apiRoutes.UPDATE_PROFILE_API,
        action: types.UPDATE_PROFILE,
        method: 'PUT',
        body,
      });
  },

  uploadPicture(body) {
    return dispatch =>
      authenticatedRequest(dispatch, {
        route: apiRoutes.UPLOAD_PICTURE_PROFILE_API,
        action: types.UPLOAD_PICTURE_PROFILE,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        body,
      });
  },

  show(query = {}, ctx = {}) {
    const queryParams = Object.keys(query)
      .map(queryParamKey => `${queryParamKey}=${query[queryParamKey]}`)
      .join('&');
    return dispatch =>
      authenticatedRequest(dispatch, {
        route: apiRoutes.SHOW_PROFILE_API(queryParams),
        action: types.SHOW_PROFILE,
        method: 'GET',
        ctx,
      });
  },
};

export default Profile;

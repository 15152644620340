import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: null,
  status: 'idle',
  error: null,
};

export const getOnboardingMessage = createAsyncThunk('onboardingMessage/getOnboardingMessage', async () => {
  const response = await apiClient.get('/v2/user/onboarding-message');
  return response.data;
});

const onboardingMessageSlice = createSlice({
  name: 'onboardingMessage',
  initialState,
  reducers: {},
  extraReducers: {
    [getOnboardingMessage.pending]: state => {
      state.status = 'loading';
    },
    [getOnboardingMessage.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [getOnboardingMessage.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default onboardingMessageSlice.reducer;

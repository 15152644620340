import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.analytics, action) => {
  switch (action.type) {
    case types.GET_ANALYTICS:
      const filters = Object.assign({}, action.payload.filters);
      delete action.payload.filters;
      return {
        ...state,
        data: action.payload
      };
    case types.GET_ANALYTICS_FILTERS:
      return {
        ...state,
        filtersData: action.payload
      };
    default:
      return state;
  }
};

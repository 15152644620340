import styled, { css } from "styled-components";
import media, { mediaMax } from "../../styles/mixins/media";
import { ReactComponent as CookieImage } from "../../images/cookie.svg";

export const Bar = styled.div`
  background: #675f72;
  border-radius: 4px;
  padding: 16px;
  position: fixed;
  bottom: 0;
  right: 0;
  transition: bottom 0.25s;
  margin-right: 32px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  z-index: 9999;

  &.hidden {
    bottom: -8rem;
  }

  ${mediaMax.sm`
    background: #675F72;
    border-radius: 8px 8px 0px 0px;
    padding-left: 8.5px;
    padding-right: 8.5px;
    margin: 0;
    right: 0;
    left: 0;
    width: auto;
  `};
`;

export const Cookie = styled(CookieImage)`
  width: 50px;
  height: 50px;

  ${mediaMax.sm`
    width: 38px;
    height: 38px;
  `};
`;

export const TextLink = styled.a`
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #ffffff;
  cursor: pointer;
  width: 224px;
  margin-left: 16px;
  margin-right: 16px;
  display: inline-block !important;

  .underline {
    text-decoration: underline;
  }

  ${mediaMax.sm`
    line-height: 110%;
  `}
`;

export const Button = styled.button`
  background: #211e42;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding: 4px 16px;
  border: 0;
  cursor: pointer;

  ${mediaMax.sm`
    padding: 0 16px;
  `}
`;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: {
    data: [],
    byNeed: [],
  },
  status: 'idle',
  error: null,
};

export const fetchPrograms = createAsyncThunk('programs/fetchPrograms', async params => {
  const response = await apiClient.get('/v1/programs', { params });
  return response.data;
});

const programsSlice = createSlice({
  name: 'programsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPrograms.pending]: state => {
      state.status = 'loading';
    },
    [fetchPrograms.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchPrograms.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default programsSlice.reducer;

export default {
  session: {
    data: {},
    errors: ''
  },
  sessions: {
    data: [],
    filtered: [],
    for_you: [],
    errors: ''
  },
  needs: {
    data: [],
    errors: ''
  },
  goal: {
    data: {},
    errors: ''
  },
  goalSessions: {
    data: [],
    filtered: [],
    errors: ''
  },
  userActions: {
    data: [],
    errors: ''
  },
  goals: {
    data: [],
    errors: ''
  },
  goalActions: {
    data: [],
    errors: ''
  },
  users: {
    data: [],
    errors: ''
  },
  coaches: {
    data: [],
    errors: ''
  },
  goalJournaling: {
    data: [],
    errors: ''
  },
  profile: {
    data: {
      sessions_count: 0,
      goals_count: 0,
      actions_count: 0,
      programs_count: 0
    },
    errors: ''
  },
  profileSessions: {
    data: [],
    errors: ''
  },
  profileGoals: {
    data: [],
    errors: ''
  },
  profileActions: {
    data: [],
    errors: ''
  },
  profilePrograms: {
    data: [],
    errors: ''
  },
  admin: {},
  user: {},
  contact: {},
  feedback: {},
  data: {
    data: [],
    errors: ''
  },
  toastr: {},
  analytics: { data: null, filtersData: null },
  sessionOpinions: {data: []},
  articleOpinions: {data: []},
  article: {
    data: {},
    errors: ''
  },
  programs: {
    data: [],
    errors: ''
  },
  program: {
    data: {},
    errors: ''
  },
  programJournaling: {
    data: [],
    errors: ''
  },
  notes: {
    data: [],
    errors: ''
  },
  programsRatings: {data: []},
};

const events = ['collectiveEvent'];
const meetings = ['buddyMeeting', 'managerMeeting', 'coachMeeting'];

export const actionService = {
  isMeeting(type) {
    return meetings.includes(type);
  },

  isEvent(type) {
    return events.includes(type);
  },
  isEventOrMeeting(type) {
    return this.isEvent(type) || this.isMeeting(type);
  },
};

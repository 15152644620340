import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.user, action) => {
  switch (action.type) {
    case types.LOGIN_USER:
    case types.SHOW_USER:
    case types.RECOVER_PASSWORD_USER:
    case types.EDIT_PASSWORD_USER:
      return action.payload;
    case types.LOGIN_USER_ERROR:
    case types.EDIT_PASSWORD_USER_ERROR:
    case types.RECOVER_PASSWORD_USER_ERROR:
      return {
        ...state,
        ...{
          errors: action.payload.errors[0],
        },
      };
    default:
      return state;
  }
};

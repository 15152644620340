import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchSession = createAsyncThunk('session/fetchSession', async ({sessionName, belongsTo, belongsToId}) => {
  const response = await apiClient.post(`/v2/sessions/start/${sessionName}`, {belongsTo, belongsToId});
  return response.data;
});

export const saveAnswer = createAsyncThunk('session/saveAnswer', async ({activeSessionId, questionId, type, content, selectedType, optionsId}) => {
  const answer = {
    type,
    content,
    selectedType,
    optionsId
  }
  await apiClient.post(`/v2/sessions/answer/${activeSessionId}/${questionId}`, answer);
  // return response.data;
});

export const saveSession = createAsyncThunk('session/saveSession', async ({activeSessionId, lumart}) => {
  const response = await apiClient.post(`/v2/sessions/complete/${activeSessionId}`, {lumart});
  return response.data;
});

//get answers or new session, if session NOT completed, but user add some answer
export const activeSession = createAsyncThunk('session/activeSession', async ({userProgramId, sessionId}) => {
  const response = await apiClient.get(`/v2/user-program/${userProgramId}/active-session/${sessionId}`);
  return response.data;
});

//all session
// export const completedSession = createAsyncThunk('session/completedSession', async (userProgramId) => {
//   const response = await apiClient.get(`v2/user-program/completed-sessions/${userProgramId}`);
//   return response.data;
// });

//get answers if session completed, doesn't matter done or not done
export const completedSession = createAsyncThunk('session/completedSession', async ({userProgramId, sessionId}) => {
  const response = await apiClient.get(`v2/user-program/${userProgramId}/completed-session/${sessionId}`);
  return response.data;
});

const sessionSlice = createSlice({
  name: 'sessionsSlice',
  initialState,
  reducers: {},
  extraReducers: {

    [fetchSession.pending]: state => {
      state.status = 'loading';
    },
    [fetchSession.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchSession.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [saveAnswer.pending]: state => {
      state.status = 'loading';
    },
    [saveAnswer.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [saveAnswer.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [saveSession.pending]: state => {
      state.status = 'loading';
    },
    [saveSession.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data.questions = action.payload.questions;
    },
    [saveSession.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [completedSession.pending]: state => {
      state.status = 'loading';
    },
    [completedSession.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      // console.log(action.payload)
    },
    [completedSession.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [activeSession.pending]: state => {
      state.status = 'loading';
    },
    [activeSession.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [activeSession.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default sessionSlice.reducer;

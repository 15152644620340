import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../../services/apiClient';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const getSessionDetailDescription = createAsyncThunk('sessions/getSessionDetailDescription', async (sessionUrl) => {
  const response = await apiClient.get(`/v2/sessions/detail-description/${sessionUrl}`);
  return response.data;
});

export const getCoachWords = createAsyncThunk('sessions/getCoachWords', async (coachId) => {
  const response = await apiClient.get(`/v2/coach/${coachId}`);
  return response.data;
});

const sessionDetailDescriptionSlice = createSlice({
  name: 'sessionDetailDescriptionSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getSessionDetailDescription.pending]: state => {
      state.status = 'loading';
    },
    [getSessionDetailDescription.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [getSessionDetailDescription.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [getCoachWords.pending]: state => {
      state.status = 'loading';
    },
    [getCoachWords.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data.coachWords = action.payload;
    },
    [getCoachWords.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default sessionDetailDescriptionSlice.reducer;

import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

import Auth from '../../services/auth';

export const withAuth = Component => {

  const currentURL = window.location.pathname + window.location.search;
  localStorage.setItem('currentURL', currentURL);
  class WithAuth extends PureComponent {
    render() {
      const user = Auth.getUser();

      if (user && user.isAuthenticated) {
        localStorage.removeItem('currentURL');
        return <Component {...this.props} user={user} />;
      }

      return <Redirect to="/login" />;
    }
  }

  return WithAuth;
};

import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.users, action) => {
  switch (action.type) {
    case types.INDEX_USERS:
    case types.UPDATE_USER:
      return {
        data: action.payload.data,
      };
    case types.CREATE_USER: {
      const users = state.data.slice();
      users.push(action.payload.data);
      return {
        data: users,
      };
    }
    case types.RESET_PASSWORD_USER:
    case types.INVITE_USER:
      return state;
    case types.CREATE_USER_ERROR:
    case types.UPDATE_USER_ERROR:
    case types.DELETE_USER_ERROR:
    case types.RESET_PASSWORD_USER_ERROR:
    case types.INVITE_USER_ERROR:
      return {
        data: state.data,
        errors: action.payload.errors[0],
      };
    case types.DELETE_USER:
      return {
        data: state.data.filter(
          user => user.id !== parseInt(action.options.id, 10)
        ),
      };
    default:
      return state;
  }
};

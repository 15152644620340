import styled, { css } from 'styled-components';
import media from '../../styles/mixins/media';

export const ToastContainer = styled.div`
  width: 100%;
  height: 60px;
  bottom: -60px;
  left: 0;
  z-index: 2001;
  background: linear-gradient(90deg, #887094 0.52%, #A1728A 25.56%, #DE7672 57.91%, #FF7865 100%);
  box-shadow: 0px 0.452492px 1.00554px rgba(0, 0, 0, 0.0646228), 0px 1.51983px 3.37739px rgba(0, 0, 0, 0.0953772), 0px 6.80429px 15.1206px rgba(0, 0, 0, 0.16);
  position: fixed;
  box-shadow: 0px 0.452492px 1.00554px rgba(0, 0, 0, 0.0646228), 0px 1.51983px 3.37739px rgba(0, 0, 0, 0.0953772), 0px 6.80429px 15.1206px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 0px 0px;
  &.appear{
    @keyframes appearMobile {
      from {
        bottom: -60px;
      }
      to {
        bottom: 0;
      }
    }
    animation: appearMobile 0.5s ease-in-out both;
  }
  &.disappear{
    @keyframes disappearMd {
      from {
        bottom: 0;
      }
      to {
        bottom: -60px;
      }
    }
    animation: disappearMd 0.5s ease-in-out both;

  }
  ${media.md`
    width: 260px;
    height: 68px;
    left: 100%;
    bottom: 0px;
    top: calc(50% - 30px);
    border-radius: 8px 0px 0px 8px;
    background: linear-gradient(90deg, #887094 0.52%, #A1728A 25.56%, #DE7672 57.91%, #FF7865 100%);
    &.appear{
      @keyframes appearMd {
        from {
          left: 100%;
        }
        to {
          left: calc(100% - 260px);
        }
      }

      animation: appearMd 0.5s ease-in-out both;
    }
    &.disappear{
      @keyframes disappearMd {
        from {
          left: calc(100% - 260px);
        }
        to {
          left: 100%;
        }
      }
      animation: disappearMd 0.5s ease-in-out both;

    }
  `}
`

import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import ReactPixel from 'react-facebook-pixel';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import LoadingBar from 'react-top-loading-bar';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/pt';

import ProfileActions from '../../reducers/actions/profile';

import { history } from '../../services/history';
import { setWithExpiry, getWithExpiry } from '../../services/chunkFailedStorage';
import Heartbeat from '../../services/heartbeat';
import requestInterval from '../../services/requestInterval';
import AppContext from '../../services/context';
import i18n from '../../services/i18n';
import Auth from '../../services/auth';

import variables from '../../styles/variables';
import RebootStyle from '../../styles/reboot';
import GlobalStyle from '../../styles/globals';
import '../../styles/antd-components';

import CookieBar from '../CookieBar';
import RewardToast from '../Toast/RewardToast';

import { withAuth } from '../../hocs/withAuth';
import { withAdminAuth } from '../../hocs/withAdminAuth';
import UserSettings from '../../reducers/actions/userSettings';

const Index = lazy(() => import('../../pages/index'));
const WelcomePage = lazy(() => import('../../features/welcome/WelcomePage'));
const WelcomeNewProgramPage = lazy(() => import('../../features/welcome/WelcomeNewProgramPage'));
const Home = lazy(() => import('../../pages/home/home'));
const Login = lazy(() => import('../../pages/login'));
const RegistrationPage = lazy(() => import('../../features/registration/RegistrationPage'));
const Articles = lazy(() => import('../../pages/articles'));
const Goals = lazy(() => import('../../pages/goals'));
const NewGoal = lazy(() => import('../../pages/new-goal'));
const Profile = lazy(() => import('../../pages/profile'));
const NewProgramPage = lazy(() => import('../../features/program/NewProgramPage'));
const ProgramsLibraryPage = lazy(() => import('../../features/program/ProgramsLibraryPage'));
const ProgramPage = lazy(() => import('../../features/program/ProgramPage'));
const ChallengePage = lazy(() => import('../../features/challenge/ChallengePage'));
const SessionsLibraryPage = lazy(() => import('../../features/session/sessionsLibraryPage'));
const SessionPage = lazy(() => import('../../features/session/sessionPage'));
const InsightsPage = lazy(() => import('../../features/insights/InsightsPage'));
const AddInsightPage = lazy(() => import('../../features/insights/AddInsightPage'));
const InsightDetailsPage = lazy(() => import('../../features/insights/InsightDetailsPage'));

const AdminDashboard = lazy(() => import('../../pages/admin/dashboard'));
const AdminLogin = lazy(() => import('../../pages/admin/login'));
const AdminUsers = lazy(() => import('../../pages/admin/users'));

const UsersPasswordCreate = lazy(() => import('../../pages/users/password/create'));
const UsersPasswordEdit = lazy(() => import('../../pages/users/password/edit'));
const Recover = lazy(() => import('../../pages/recover'));
const ConfirmationPage = lazy(() => import('../../features/partner/confirmation/ConfirmationPage'))
const Privacy = lazy(() => import('../../pages/privacy'));

const ProgramMethodologyPage = lazy(() => import('../../features/blog/ProgramMethodologyPage'));
const LuminousMethodologyPage = lazy(() => import('../../features/blog/LuminousMethodologyPage'));
const CertificatePage = lazy(() => import('../../features/certificate/CertificatePage'));

const ArticlesWrapped = withAuth(Articles);
const GoalsWrapped = withAuth(Goals);
const WelcomePageWrapped = withAuth(WelcomePage);
const WelcomeNewProgramPageWrapped = withAuth(WelcomeNewProgramPage);
const HomeWrapped = withAuth(Home);
const NewGoalWrapped = withAuth(NewGoal);
const NewProgramPageWrapped = withAuth(NewProgramPage);
const ProfileWrapped = withAuth(Profile);
const ProgramsLibraryPageWrapped = withAuth(ProgramsLibraryPage);
const ProgramPageWrapped = withAuth(ProgramPage);
const ChallengePageWrapped = withAuth(ChallengePage);
const SessionsLibraryPageWrapped = withAuth(SessionsLibraryPage);
const SessionPageWrapped = withAuth(SessionPage);
const InsightsPageWrapped = withAuth(InsightsPage);
const AddInsightPageWrapped = withAuth(AddInsightPage);
const InsightDetailsPageWrapped = withAuth(InsightDetailsPage);

const AdminDashboardWrapped = withAdminAuth(AdminDashboard);
const AdminUsersWrapped = withAdminAuth(AdminUsers);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      translations: i18n.load(),
      lang: 'EN',
      completedOnboarding: false,
      userRole: '',
    };
  }

  componentDidMount() {

    window.addEventListener('error', e => {
      if (/Loading chunk [\d]+ failed/.test(e.message)) {
        if (!getWithExpiry()) {
          setWithExpiry('true', 10000);
          window.location.reload();
        }
      }
    });

    const user = Auth.getUser();

    if (user && user.isAuthenticated) {

      this.props.getUserSettings().then(resp => {
        if (resp?.payload) {
          this.setState({ completedOnboarding: resp.payload.completedOnboardingJourney });
        }
      })

      this.props.getProfile().then(resp => {
        if (resp?.payload) {
          this.setState({ userRole: resp.payload.data.role });
        }
      });
      Heartbeat.send();
      this.interval = requestInterval(() => {
        Heartbeat.send();
      }, 60 * 1000);

      if (user.lang) {
        Auth.changeLanguage(user.lang);
        moment.locale(user.lang);
        const translations = i18n.load();

        this.setState({
          translations,
          lang: user.lang,
        });
      }
    } else {
      const lang = window.navigator.language.slice(0, 2);
      Auth.changeLanguage(lang.toUpperCase());
      moment.locale(Auth.getLanguage());
      const translations = i18n.load();
      this.setState({ translations, lang });
    }

    if (process.env.REACT_APP_NODE_ENV === 'production' && process.env.REACT_APP_SITE_URL === 'https://becomeluminous.com') {
      this.initGA();
      this.initFacebookPixel();
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      window.cancelAnimationFrame(this.interval);
    }
  }

  initGA() {
    const TRACKING_ID = process.env.REACT_APP_GTM;
    if (TRACKING_ID && window.dataLayer) {
      window.dataLayer.push({
        page_title: document.title,
        page_path: window.location.pathname + window.location.search,
      });

      history.listen(location => {
        const page = location.pathname + location.search;
        window.dataLayer.push({
          page_title: document.title,
          page_path: page,
        });
      });
    }
  }

  initFacebookPixel() {
    ReactPixel.init('996158027489990');
    ReactPixel.pageView(); // For tracking page view
  }

  render() {
    const { completedOnboarding, userRole } = this.state;
    return (
      <ThemeProvider theme={[variables].reduce((p, c) => Object.assign(p, c))}>
        <RebootStyle />
        <GlobalStyle theme={variables} />
        <Router history={history}>
          <AppContext.Provider value={this.state.translations}>
            <Route
              render={({ location }) => {
                return (
                  <TransitionGroup>
                    <CSSTransition key={location.key} classNames="page-transition" timeout={500}>
                      <Suspense fallback={<LoadingBar height={3} progress={80} color="#fc6453" />}>
                        <Switch location={location}>
                          <Route path="/" exact render={props => <Login {...props} />} />
                          <Route path="/articles/:id" exact render={props => <ArticlesWrapped {...props} />} />
                          <Route path="/goals/:id" exact render={props => <GoalsWrapped {...props} />} />

                          <Route
                            path="/welcome"
                            exact
                            render={props => (completedOnboarding ? <Redirect to="/home" /> : <WelcomePageWrapped {...props} />)}
                          />
                          <Route path="/welcome/new-program/:id" exact render={props => <WelcomeNewProgramPageWrapped {...props} />} />
                          <Route path="/home" exact render={props => <HomeWrapped {...props} />} />
                          <Route path="/new-goal" exact render={props => <NewGoalWrapped {...props} />} />
                          <Route path="/new-program/:id" exact render={props => <NewProgramPageWrapped {...props} />} />
                          <Route path="/profile" exact render={props => <ProfileWrapped {...props} />} />
                          <Route path="/programs-library" exact render={props => <ProgramsLibraryPageWrapped {...props} />} />
                          {/* <Route path="/new-program/:id" exact render={props => <ProgramPageWrapped {...props} />} /> */}
                          <Route path="/programs/:id" exact render={props => <ProgramPageWrapped {...props} />} />
                          <Route path="/programs/:id/challenge/:actionId" exact render={props => <ChallengePageWrapped {...props} />} />
                          <Route path="/sessions-library" exact render={props => <SessionsLibraryPageWrapped {...props} />} />
                          <Route path="/sessions/:id" exact render={props => <SessionPageWrapped {...props} />} />
                          <Route path="/insights" exact render={props => <InsightsPageWrapped {...props} />} />
                          <Route path="/insights/add-an-insight" exact render={props => <AddInsightPageWrapped {...props} />} />
                          <Route path="/insights/insight-details" exact render={props => <InsightDetailsPageWrapped {...props} />} />

                          <Route path="/admin/login" exact render={props => <AdminLogin {...props} />} />
                          <Route path="/admin/dashboard" exact render={props => <AdminDashboardWrapped {...props} />} />
                          <Route path="/admin/users/:id" exact render={props => <AdminUsersWrapped {...props} />} />

                          <Route path="/users/password/create" exact render={props => <UsersPasswordCreate {...props} />} />
                          <Route path="/users/password/edit" exact render={props => <UsersPasswordEdit {...props} />} />
                          <Route path="/recover" exact render={props => <Recover {...props} />} />
                          <Route path="/mentor/program/accept" exact render={props => <ConfirmationPage isProgram {...props} />} />
                          <Route path="/mentor/goal/accept" exact render={props => <ConfirmationPage {...props}/>} />
                          <Route path="/privacy" exact render={props => <Privacy {...props} />} />
                          <Route path="/login" exact render={props => <Login {...props} />} />
                          <Route path="/registration/:key" exact render={props => <RegistrationPage {...props} />} />

                          <Route path="/blog/program-methodology" exact render={props => <ProgramMethodologyPage {...props} />}/>
                          <Route path="/blog/luminous-methodology" exact render={props => <LuminousMethodologyPage {...props} />}/>
                          <Route path="/certificates/:key" exact render={props => <CertificatePage {...props} />}/>

                          {/* test url */}
                          <Route path="/test-welcome" exact render={props => <WelcomePageWrapped {...props} />} />
                          <Route path="*">404 Not Found</Route>
                        </Switch>
                      </Suspense>
                    </CSSTransition>
                  </TransitionGroup>
                );
              }}
            />
            <RewardToast getState={state => state.toastr} />
            <CookieBar />
          </AppContext.Provider>
        </Router>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = ({ profile }) => {
  return {
    profile,
  };
};

const mapDispatchToProps = dispatch => ({
  getProfile: () => dispatch(ProfileActions.show()),
  getUserSettings: () => dispatch(UserSettings.getUserSettings()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { setCookie, getCookie } from "../../services/cookies";
import AppContext from "../../services/context";
import { Bar, Cookie, TextLink, Button } from "./styles";

class CookieBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accepted: !!getCookie("cookies_allowed"),
      hidden: true
    };
    this.barRef = React.createRef();
    this.timeout = null;
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ hidden: false });
    }, 2000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleAcceptCookies = () => {
    if (!this.state.hidden) {
      this.setState({ hidden: true });
      setCookie("cookies_allowed", 1);
      this.barRef.current.addEventListener("transitionend", () => {
        this.setState({ accepted: true });
      });
    }
  };

  render() {
    return (
      <>
        {!this.state.accepted ? (
          <AppContext.Consumer>
            {context => (
              <Bar
                className={this.state.hidden ? "hidden" : ""}
                ref={this.barRef}
              >
                <Cookie />
                <Link to="/privacy#cookies-policy">
                  <TextLink
                    dangerouslySetInnerHTML={{
                      __html: context.cookies_modal_text
                    }}
                  />
                </Link>
                <Button type="button" onClick={this.handleAcceptCookies}>
                  {context.cookies_modal_button}
                </Button>
              </Bar>
            )}
          </AppContext.Consumer>
        ) : null}
      </>
    );
  }
}

export default CookieBar;

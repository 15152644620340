import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.sessions, action) => {
  switch (action.type) {
    case types.INDEX_SESSIONS:
      return {
        data: action.payload.data,
        filtered: action.payload.data,
      };
    case types.INDEX_SESSIONS_FORHOME:
      return {
        data: action.payload.data,
      };
    case types.FILTER_BY_NEED_SESSIONS: {
      if (action.payload.id !== null) {
        let sessionsFiltered = [];
        if (action.payload.id === 'FOR_YOU') {
          sessionsFiltered = state.data.filter(session =>
            [
              'S0004',
              'S0011',
              'S0001',
              'S0012',
              'S0006',
              'S0009',
              'S0005',
              'S0031',
            ].includes(session.session_id)
          );
        } else {
          sessionsFiltered = state.data.filter(session =>
            session.needs.map(need => need.need_id).includes(action.payload.id)
          );
        }
        return {
          data: state.data,
          filtered: sessionsFiltered,
        };
      }
      return {
        data: [],
        filtered: [],
      };
    }
    case types.INDEX_SESSIONS_WITHFILTER:
      return {
        data: action.payload.data,
        filtered: action.payload.data,
      };
    case types.INDEX_SESSIONS_FOR_YOU:
      return {
        data: state.data,
        filtered: state.data,
        for_you: action.payload.data,
      };
    default:
      return state;
  }
};

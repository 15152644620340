import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.contact, action) => {
  switch (action.type) {
    case types.SEND_CONTACT:
      return action.payload;
    case types.SEND_MESSAGE_HOME_API:
      return action.payload;
    case types.SEND_CONTACT_ERROR:
      return action.payload;
    default:
      return state;
  }
};
